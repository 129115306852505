import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { getToken } from '@/utils/auth'
import { getInfo } from '@/api/login'
Vue.use(VueRouter)

const routes = [
  // 登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    hidden: true, // 设置hidden表示在菜单栏中不显示
  },
  // 总馆
  {
    path: '/', 
    // path: '/logo', 
    redirect: '/logo',
    component: Layout,
    children: [
      {
        path: '',
        name: 'logo',
        component: () => import('@/views/logo'),
        // role: 'manager'   要指定
        meta: { title: '沐心图书馆', role: 'manager'  }
      }
    ]
  },
  {
    path: '/views/dashboard',
    component: Layout,
    // redirect: '/dashboard',
    children: [
      {
        path: '',
        // path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard'),
        meta: { title: '首页', icon: 'dashboard',role: 'manager'  }
      }
    ]
  },
  {
    path: '/dashboard',
    component: Layout,
    // redirect: '/dashboard',
    children: [
      {
        path: '',
        // path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard'),
        meta: { title: '首页', icon: 'dashboard',role: 'admin'  }
      }
    ]
  },
  {
    path: '/admin/book',  
    component: Layout,
    children: [
      {
        path: '',
        name: 'book',
        component: () => import('@/views/admin/book'),
        meta: { title: '图书管理', icon: 'book', role: 'manager' }
      }
    ]
  },
  {
    path: '/admin/bookdetail',
    component: Layout,
    children: [
      {
        path: '',
        name: 'bookdetail',
        component: () => import('@/views/admin/bookdetail'),
        meta: { title: '图书详情', icon: 'book', role: 'manager' },
      }
    ],
    hidden: true, // 设置hidden表示在菜单栏中不显示

  }
  ,
  {
    path: '/reader/lend-record',
    component: Layout,
    children: [
      {
        path: '',
        name: 'adminLend-regulate',
        component: () => import('@/views/reader/lend-record'),
        meta: { title: '借阅记录', icon: 'lend-record', role: 'manager' }
      }
    ]
  },
  // {
  //   path: '/admin/reader',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'adminReader',
  //       // component: () => import('@/views/admin/reader'),
  //       meta: { title: '读者管理', icon: 'reader', role: 'manager' },

  //       children: [{
  //         path: '/reader/readerList',
  //         name: 'adminreaderList',
  //         component: () => import('@/views/admin/reader/readerList'),
  //         meta: { title: '读者列表', icon: 'reader', role: 'manager' },
  //       }, {
  //         path: '/reader/readerSetting',
  //         name: 'adminreaderSetting',
  //         component: () => import('@/views/admin/reader/readerSetting'),
  //         meta: { title: '读者设置', icon: 'reader', role: 'manager' },
  //       }]
  //     }
  //   ]
  // },
  // 数据中心
  {
    path: '/admin/datahub',
    component: Layout,
    children: [
      {
        path: '',
        name: 'adminReader',
        component: () => import('@/views/admin/datahub'),
        // component: () => import('@/views/data/count'),
        meta: { title: '数据中心', icon: 'reader', role: 'manager' }
      }
    ]
  },
  // 测试 读者列表
  {
    path: '/views/readerList',
    component: Layout,
    children: [{
      path: '',
      name: 'adminReaderList',
      component: () => import('@/views/readerList'),
      meta: { title: '读者列表', icon: 'reader', role: 'manager' }
    }]
  },
  // 测试 读者设置
  // {
  //   path: '/admin/readerSetting',
  //   component: Layout,
  //   children: [{
  //     path: '',
  //     name: 'adminReaderSetting',
  //     component: () => import('@/views/admin/readerSetting'),
  //     meta: { title: '读者设置', icon: 'reader', role: 'manager' }
  //   }]
  // },
  // 测试 图书馆设置
  {
    path: '/admin/librariesList',
    component: Layout,
    children: [{
      path: '',
      name: 'adminlibrariesList',
      component: () => import('@/views/admin/librariesList'),
      meta: { title: '图书馆设置', icon: 'lend-record', role: 'manager' }
    }]
  },
  {
    path: '/admin/memberList',
    component: Layout,
    children: [
      {
        path: '',
        name: 'adminMemberList',
        component: () => import('@/views/admin/memberList'),
        meta: { title: '图书馆成员管理', icon: 'lend-record', role: 'manager' }
      }
    ]
  },
  // {
  //   path: '/admin/lend-record',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'adminLend-regulate',
  //       component: () => import('@/views/admin/lend-record'),
  //       meta: { title: '借阅记录', icon: 'lend-record', role: 'manager' }
  //     }
  //   ]
  // },

  // {
  //   path: '/admin/librariesList',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'adminLibrariesList',
  //       component: () => import('@/views/admin/librariesList'),
  //       meta: { title: '图书馆管理', icon: 'lend-record', role: 'manager' },
  //       children: [{
  //         path: '/admin/librariesList/librariesSetting',
  //         name: 'adminLibrariesSetting',
  //         component: () => import('@/views/admin/librariesList/librariesSetting'),
  //         meta: { title: '图书馆设置', icon: 'lend-record', role: 'manager' }
  //       }, {
  //         path: '/admin/librariesList/librariesMember',
  //         name: 'adminLibrariesMember',
  //         component: () => import('@/views/admin/librariesList/librariesMember'),
  //         meta: { title: '成员管理', icon: 'lend-record', role: 'manager' }
  //       },]
  //     },
  //   ]
  // },




  // ====================普通分馆============================
    // 数据中心
    {
      path: '/reader/datahub',
      component: Layout,
      children: [
        {
          path: '',
          name: 'adminReader',
          component: () => import('@/views/reader/datahub'),
          // component: () => import('@/views/data/count'),
          meta: { title: '数据中心', icon: 'reader', role: 'admin' }
        }
      ]
    },
  {
    path: '/reader/book',
    component: Layout,
    children: [
      {
        path: '',
        name: 'readerBook',
        component: () => import('@/views/reader/book'),
        meta: { title: '图书管理', icon: 'book', role: 'admin' , keepAlive: false}
      }
    ]
  },
  {
    path: '/views/readerList',
    component: Layout,
    children: [{
      path: '',
      name: 'adminReaderList',
      component: () => import('@/views/readerList'),
      meta: { title: '读者列表', icon: 'reader', role: 'admin' }
    }]
  },
  {
    path: '/reader/addReader',
    component: Layout,
    children: [
      {
        path: '',
        name: 'adminAddReader',
        component: () => import('@/views/reader/addReader'),
        meta: { title: '新增读者', role: 'admin' }
      }
    ]
  },
  {
    path: '/reader/lend-record',
    component: Layout,
    children: [
      {
        path: '',
        name: 'readerLend-regulate',
        component: () => import('@/views/reader/lend-record'),
        meta: { title: '借阅记录', icon: 'lend-record', role: 'admin' }
      }
    ]
  },
  {
    path: '/reader/librariesList',
    component: Layout,
    children: [
      {
        path: '',
        name: 'readerLibrariesList',
        component: () => import('@/views/reader/librariesList'),
        meta: { title: '图书馆管理', icon: 'lend-record', role: 'admin' },
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history', //去掉url中的#
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    const token = getToken()
    // console.log(token)
    const res2 = await getInfo(getToken())
    // console.log(res2)
    // let res
    // await getInfo(getToken()).then(({ data }) => {
    //   res = data
    // })
    if (token==null || res2 == null) {
      next('/login')
    } else {
      next()
    }
  }
})

// 避免elementui面包屑重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
