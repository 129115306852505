const role = 'authRole'
const token = 'Authorization'
const aid = 'authAid'
const bid = 'authBid'
// 存token 
export function setToken(value) {
  console.log(token,value);
  sessionStorage.setItem(token, value)
}
// 取token
export function getToken() {
  return sessionStorage.getItem(token)
}
// 清除角色权限
export function clearToken() {
  sessionStorage.clear(token)
}
// 存角色权限
export function setRole(val) {
  sessionStorage.setItem(role, val)
}
export function getRole() {
  return sessionStorage.getItem(role)
}


// 存aid和bid，获取aid和bid
export function setAid(val) {
  sessionStorage.setItem(aid, val)
}
export function getAid() {
  return sessionStorage.getItem(aid)
}
export function setBid(val) {
  sessionStorage.setItem(bid, val)
}
export function getBid() {
  return sessionStorage.getItem(bid)
}

//设置当前官族的名字
export function setUserName(val) {
  sessionStorage.setItem("userName", val)
}
export function getUserName() {
  return sessionStorage.getItem("userName")
}


//设置当前的  官族的  颜色选中

export function setColor(val) {
  sessionStorage.setItem("color", val)
}
export function getColor() {
  return sessionStorage.getItem("color")
}